import { Pagination } from 'swiper'

import classNames from './styles.module.scss'


const swiperConfig = {
  spaceBetween: 5,
  slidesPerView: 1,
  slidesPerGroup: 1,
  speed: 0,
  mousewheel: true,
  cssMode: true,
  allowTouchMove: false,
  preventClicks: false,
  preventClicksPropagation: false,
  observer: true,
  observeParents: true,
  breakpoints: {
    200: {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
    411: {
      slidesPerView: 2,
      spaceBetween: 5,
      slidesPerGroup: 2,
    },
    760: {
      slidesPerView: 3,
      spaceBetween: 5,
      slidesPerGroup: 3,
    },
    960: {
      slidesPerView: 4,
      spaceBetween: 10,
      slidesPerGroup: 4,
    },
  },
  modules: [Pagination],
  className: classNames.swiper,
}

export default swiperConfig
