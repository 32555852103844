import { listLinks } from '@common/constants'
import { useTranslate } from '@common/hooks'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import classNames from './styles.module.scss'


interface IProps {
  reducer: AppListReducer,
  className?: string,
  tabIndex: number,
}


function CategoryLink({
  reducer,
  className,
  tabIndex,
}: IProps): JSX.Element {
  const { translate } = useTranslate()
  const { query } = useRouter()
  return (
    <Link
      legacyBehavior
      href={{
        pathname: `/apps/${listLinks[reducer]}`,
        query,
      }}
    >
      <a
        className={cx(classNames.link, className)}
        tabIndex={tabIndex}
        data-test={`${reducer}-link-seeMore`}
      >
        {translate('See all')}
      </a>
    </Link>
  )
}

CategoryLink.defaultProps = {
  className: undefined,
}


export default CategoryLink
