import type { Swiper } from 'swiper'

export default function handleSlidePrevPage(swiper: Swiper): void {
  if(!swiper) {
    return
  }
  let value = swiper.activeIndex - (swiper.params.slidesPerGroup || 0)
  if(swiper.slides.length === swiper.activeIndex + (swiper?.params.slidesPerGroup || 0)) {
    value = value + (swiper.params.slidesPerGroup || 0) - 1
  }
  swiper.slideTo(value)
}
