import cx from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import classNames from './styles.module.scss'
import swiperConfig from './swiperPaginationConfig'
import SwipperDot from './SwipperDot'


interface IProps {
  paginationItems: number[],
  handleMoveMainSlider: (ind: number) => void,
  snapIndex: number,
  tabIndex: number,
  reducer: AppListReducer,
}

export default function Pagination({
  handleMoveMainSlider,
  snapIndex,
  paginationItems,
  tabIndex,
  reducer,
}: IProps): JSX.Element {
  const slider = useRef<SwiperRef>(null)
  const [activeElem, setActive] = useState(0)

  const items = useMemo(() => {
    return paginationItems.map(
      (el, ind) => {
        return (
          <SwiperSlide key={el}>
            <SwipperDot ind={ind} activeElem={activeElem} tabIndex={tabIndex + ind} dataTest={`dot-${reducer}-${ind}`}/>
          </SwiperSlide>
        )
      }
    )
  }, [paginationItems, activeElem])

  const onSlideChange = () => {
    if(slider.current && slider.current.swiper) {
      setActive(slider.current.swiper.realIndex)
      handleMoveMainSlider(slider.current.swiper.realIndex)
    }
  }

  useEffect(() => {
    if(slider.current && slider.current.swiper) {
      if(slider.current.swiper.realIndex !== snapIndex) {
        const timer = setTimeout(() => {
          slider.current && slider.current.swiper.slideTo(snapIndex)
        }, 100)
        return () => {
          clearTimeout(timer)
        }
      }
    }
  }, [snapIndex])

  return (
    <div className={cx(classNames.wrapper, classNames.mobile)}>
      {items.length > 1 && (
        <Swiper
          {...swiperConfig}
          initialSlide={snapIndex}
          ref={slider}
          onSlideChange={onSlideChange}
        >
          {items}
        </Swiper>
      )}
    </div>
  )
}
