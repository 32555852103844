export default function handleReorderApps(apps: IApplication[], level: number): IApplication[] {
  if(level === 1) {
    return apps
  }
  const reorderedApps = []
  for(let i = 0; i < apps.length; i += 3 * level) {
    for(let j = 0; j < level; j++) {
      if(apps[i + j]) { reorderedApps.push(apps[i + j]) }
      if(apps[i + j + level]) { reorderedApps.push(apps[i + j + level]) }
      if(apps[i + j + level * 2]) { reorderedApps.push(apps[i + j + level * 2]) }
    }
  }
  return reorderedApps.length ? reorderedApps : apps
}
