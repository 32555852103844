import cx from 'classnames'

import classNames from './styles.module.scss'

interface IProps {
  direction: 'right' | 'left'
  disabled?: boolean
  onClick(): void,
  tabIndex: number,
}

function CarouselActionButton({ direction, disabled, onClick, ...props }: IProps): JSX.Element {
  return (
    <button disabled={disabled} className={cx(classNames.wrapper, disabled && classNames.disabled, direction === 'left' && classNames.rotate)} onClick={onClick} {...props}>
      { disabled && (
        <span className={cx(classNames.arrowNav, classNames.disabled)} />
      )}
      { !disabled && (
        <span className={classNames.arrowNav} />
      )}
    </button>
  )
}

CarouselActionButton.defaultProps = {
  disabled: false,
}

export default CarouselActionButton
