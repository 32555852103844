import cx from 'classnames'
import { useSwiper, useSwiperSlide } from 'swiper/react'

import classNames from './styles.module.scss'


interface IProps {
  ind: number,
  activeElem: number,
  tabIndex: number,
  dataTest: string,
}

export default function SwipperDot({
  ind,
  activeElem,
  tabIndex,
  dataTest,
}: IProps): JSX.Element {
  const swiperSlide = useSwiperSlide()
  const swiper = useSwiper()
  const handleClickBullet = () => {
    swiper.slideTo(ind)
  }
  return (
    <div
      data-test={dataTest}
      className={cx(
        classNames.bullet,
        activeElem + 2 === ind && classNames.sm,
        activeElem - 2 === ind && classNames.sm,
        activeElem + 3 === ind && classNames.xs,
        activeElem - 3 === ind && classNames.xs,
        activeElem + 4 === ind && classNames.xs,
        activeElem - 4 === ind && classNames.xs,
      )}
      onClick={handleClickBullet}
      tabIndex={tabIndex}
      onKeyDown={(ev) => {
        if(ev.key === 'Enter') {
          handleClickBullet()
        }
      }}
    >
      <span className={cx(classNames.dot, swiperSlide.isActive && classNames.active)}/>
    </div>
  )
}
