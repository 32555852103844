import { useTranslate } from '@common/hooks'
import getSortOrderTitle from '@common/utils/getSortOrderTitle'

import AppGrid from './AppGrid'


interface IProps {
  showHackathonWinners: boolean
}

export default function ServerRootPageGrid({
  showHackathonWinners,
}: IProps): JSX.Element {
  const { translate } = useTranslate()
  return <main>
    {showHackathonWinners && (
      <AppGrid
        title={translate(getSortOrderTitle('hackathonWinners'))}
        reducer="hackathonWinners"
      />
    )}
    <AppGrid
      rowsPerSlide={3}
      title={translate(getSortOrderTitle('topEarning'))} reducer="topEarning"
    />
    <AppGrid
      title={translate(getSortOrderTitle('trendingApps'))} reducer="trendingApps"
    />
    <AppGrid
      title={translate(getSortOrderTitle('newAndPopular'))} reducer="newAndPopular"
    />
    <AppGrid
      title={translate(getSortOrderTitle('recentlyUpdated'))}
      reducer="recentlyUpdated"
    />
</main>
}
