import CarouselActionButton from '@apps/AppList/widgets/AppCarousel/CarouselActionButton'
import { emptyApplicationsArray } from '@common/constants'
import { useAppSelector } from '@common/hooks'
import type { RefObject } from 'react'
import { useEffect, useState } from 'react'
import type { SwiperRef } from 'swiper/react'

import classNames from './styles.module.scss'
import handleCheckSwiperIsEnd from './utils/handleCheckSwiperIsEnd'
import handleSlidePrevPage from './utils/handleSlidePrevPage'


interface IProps {
  reducer: AppListReducer,
  slider: RefObject<SwiperRef>,
  activeElem: number,
  pagination: number[],
  tabIndex: number,
}

export default function CarouselNavigation({ slider, activeElem, reducer, pagination, tabIndex }: IProps): JSX.Element {
  const [isEnd, setEnd] = useState(false)
  const applicationList = useAppSelector(store => {
    return store[reducer]?.data?.items || emptyApplicationsArray
  })

  const handleGoNextSlide = () => {
    if(slider.current) {
      slider.current.swiper.slideNext(0)
      if(handleCheckSwiperIsEnd(slider.current.swiper)) {
        setEnd(true)
      }
    }
  }

  const handleGoPreviousSlide = () => {
    if(slider.current) {
      handleSlidePrevPage(slider.current.swiper)
      setEnd(false)
    }
  }

  useEffect(() => {
    if(!applicationList.length || (slider.current && handleCheckSwiperIsEnd(slider.current.swiper))) {
      setEnd(true)
    } else {
      setEnd(false)
    }
  }, [pagination, activeElem])

  return (
    <div className={classNames.navigation}>
      <CarouselActionButton
        direction="left"
        onClick={handleGoPreviousSlide}
        disabled={activeElem === 0 || !applicationList.length}
        data-test={`${reducer}-slide-previous-btn`}
        tabIndex={tabIndex + 3}
      />
      <CarouselActionButton
        direction="right"
        onClick={handleGoNextSlide}
        disabled={isEnd}
        data-test={`${reducer}-slide-next-btn`}
        tabIndex={tabIndex + 6}
      />
    </div>
  )
}
