import classNames from './styles.module.scss'


const swiperConfig = {
  spaceBetween: 4,
  slidesPerView: 5,
  slidesPerGroup: 1,
  className: classNames.swiper,
  centeredSlides: true,
}

export default swiperConfig
