import { emptyApplicationsArray, listLinks, tabIndexesByReducer } from '@common/constants'
import { useAppDispatch, useAppSelector } from '@common/hooks'
import handleReorderApps from '@common/utils/handleReorderApps'
import AppCard, { RedirectСard } from '@common/widgets/AppCard'
import { EmptyAppList } from '@common/widgets/EmptySections'
import { OneClickButton } from '@common/widgets/OneClickInstallation'
import { appListFetchThunks } from '@redux/slices/applicationList'
import { setCarouselsInfo, setResetCarousels } from '@redux/slices/common'
import cx from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import CarouselNavigation from './CarouselNavigation'
import CategoryLink from './CategoryLink'
import { MobilePagination, Pagination } from './Pagination'
import classNames from './styles.module.scss'
import swiperConfig from './swiperConfig'
import handleCheckSwiperIsEnd from './utils/handleCheckSwiperIsEnd'
import handleGetSwiperIndex from './utils/handleGetSwiperIndex'


interface IProps {
  title: string,
  reducer: AppListReducer,
  clientRequest?: boolean,
  rowsPerSlide?: number,
}

AppCarousel.defaultProps = {
  clientRequest: false,
  rowsPerSlide: 1,
}

let baseSlidesPerGroup = 3

export default function AppCarousel({ title, reducer, clientRequest, rowsPerSlide }: IProps): JSX.Element {
  const dispatch = useAppDispatch()
  const slider = useRef<SwiperRef>(null)

  const applicationList = useAppSelector(store => store[reducer]?.data?.items || emptyApplicationsArray)
  const applicationListData = useAppSelector(store => store[reducer]?.data)
  const applicationInitialSaved = useAppSelector(store => store[reducer]?.initialSaved)
  const isResetCarousels = useAppSelector(store => store.common.data.isResetCarousels)
  const initialSlide = useAppSelector(store => store.common.data.carouselsDefaultSlides ? store.common.data.carouselsDefaultSlides[reducer] : 0)
  const winners = useAppSelector(store => reducer === 'hackathonWinners' ? store.hackathonWinners.winners : null)

  const [activeElem, setActiveElem] = useState(0)
  const [pagination, setPagination] = useState<number[]>([])
  const [snapIndex, setSnapIndex] = useState(0)
  const [slidesPerGroup, setSlidesPerGroup] = useState<number | undefined>()
  const [isInitialSlideSet, setIsInitialSlideSet] = useState(false)

  const slides = useMemo(() => {
    if((applicationList.length === 0 && !clientRequest) || !rowsPerSlide) { return [] }
    let applications = []
    if(rowsPerSlide === 1) {
      applications = applicationList.map(
        (app, ind, arr) => {
          let winnerPlace = winners?.findIndex(winner => winner.id === app.id)
          winnerPlace === undefined && (winnerPlace = -1)
          const isWinner = winnerPlace !== -1
          const slidesGrouped = slidesPerGroup || 0
          const allowTabIndex = ind < (snapIndex + 1) * slidesGrouped && (ind >= snapIndex * slidesGrouped || arr.length - slidesGrouped <= ind - 1)
          return (
            <SwiperSlide key={app.id} data-test={`slide-${reducer}`}>
              <AppCard
                {...app}
                application={app}
                dataTest={`app-${reducer}`}
                className={cx(classNames.card, {
                  [classNames.shortTitle]: isWinner,
                  [classNames.borderWinner]: isWinner,
                  [classNames[`borderWinner${winnerPlace + 1}`]]: isWinner,
                })}
                tabIndex={allowTabIndex ? tabIndexesByReducer[reducer] + 9 + ind : -1}
              >
                <OneClickButton app={app} reducer={reducer}/>
                {isWinner && <i className={cx(classNames.hackathonWinnersIcon, classNames[`winner${winnerPlace + 1}`])} />}
              </AppCard>
            </SwiperSlide>
          )
        }
      )
      if(slider.current?.swiper.params.slidesPerGroup ? applications.length > slider.current?.swiper.params.slidesPerGroup : applications.length > baseSlidesPerGroup) {
        baseSlidesPerGroup = slider.current?.swiper.params.slidesPerGroup || baseSlidesPerGroup
        applications.push(
          <SwiperSlide key="load more" data-test={`${reducer}-load-more-btn`}>
            <RedirectСard className={classNames.card} link={listLinks[reducer]} tabIndex={applications.length < baseSlidesPerGroup * (snapIndex + 1) ? tabIndexesByReducer[reducer] + 40 : -1}/>
          </SwiperSlide>
        )
      }
    } else {
      let readMoreWasAdded = false
      const reorderedApps = handleReorderApps(applicationList, slidesPerGroup || 1)
      for(let i = 0; i < reorderedApps.length; i += rowsPerSlide) {
        const endIndex = i + rowsPerSlide
        const isLastIteration = endIndex >= reorderedApps.length
        const slideItems = reorderedApps.slice(i, i + rowsPerSlide).map((app, ind) => (
          <div key={app.id} className={classNames.rowItem}>
            <AppCard
              {...app}
              application={app}
              dataTest={`app-${reducer}`}
              className={classNames.card}
              tabIndex={tabIndexesByReducer[reducer] + 9 + ind}
            >
              <OneClickButton app={app} reducer={reducer} />
            </AppCard>
          </div>
        ))
        if(isLastIteration && slideItems.length < 3) {
          readMoreWasAdded = true
          slideItems.push(
            <div key="load more" className={classNames.rowItem}>
              <RedirectСard className={classNames.card} link={listLinks[reducer]} tabIndex={applications.length < baseSlidesPerGroup * (snapIndex + 1) ? tabIndexesByReducer[reducer] + 40 : -1}/>
            </div>
          )
        }
        applications.push(
          <SwiperSlide key={`slide-${i}`} data-test={`slide-${reducer}`}>
            {slideItems}
          </SwiperSlide>
        )
        if(isLastIteration && slideItems.length === 3 && !readMoreWasAdded) {
          applications.push(
            <SwiperSlide key="read-more" data-test="slide-read-more">
              <div className={cx(classNames.rowItem, classNames.emptyItem)} />
              <div className={cx(classNames.rowItem, classNames.emptyItem)} />
              <div key="load more" className={classNames.rowItem}>
                <RedirectСard className={classNames.card} link={listLinks[reducer]} tabIndex={applications.length < baseSlidesPerGroup * (snapIndex + 1) ? tabIndexesByReducer[reducer] + 40 : -1}/>
              </div>
            </SwiperSlide>
          )
        }
      }
    }

    if(!applicationInitialSaved && (clientRequest || !applicationListData)) {
      const slidesPerView = slider.current?.swiper.params.slidesPerView as number || 3
      for(let i = 0; i < slidesPerView; i++) {
        applications.unshift(
          <SwiperSlide key={i} data-test="slide-loading" className="loading">
            <Skeleton className={classNames.skeleton} duration={0.5}/>
          </SwiperSlide>
        )
      }
    }
    return applications
  }, [applicationList, snapIndex, slidesPerGroup, applicationInitialSaved, winners])

  const onSlideChange = () => {
    if(slider.current) {
      setActiveElem(slider.current.swiper.activeIndex)
      setPagination(slider.current.swiper.snapGrid)
      setSnapIndex(handleCheckSwiperIsEnd(slider.current.swiper) ? slider.current.swiper.snapGrid.length - 1 : slider.current.swiper.snapIndex)
    }
  }

  const handleMoveMainSlider = (ind: number): void => {
    if(slider.current) {
      slider.current.swiper.slideTo(ind * Number(slider.current.swiper.params.slidesPerGroup))
    }
  }

  useEffect(() => {
    if(slider.current) {
      setPagination(slider.current.swiper.snapGrid)
      setSnapIndex(slider.current.swiper.snapIndex)
    }
  }, [applicationList])

  useEffect(() => {
    if(slider.current?.swiper.slides.length && !slider.current?.swiper.slides[0].classList.contains('loading')) {
      dispatch(setCarouselsInfo({ [reducer]: handleGetSwiperIndex(slider.current?.swiper) }))
    }
  }, [activeElem])

  const initialRequest = useRef(true)

  useEffect(() => {
    if((clientRequest || !applicationListData) && typeof window !== 'undefined') {
      initialRequest.current && void dispatch(appListFetchThunks[reducer]({ limit: 25, offset: 0 }))
      initialRequest.current = false
    }
  }, [])

  useEffect(() => {
    if(isResetCarousels) {
      dispatch(setResetCarousels(false))
      setTimeout(() => {
        slider.current?.swiper.slideTo(0)
      }, 0)
    }
  }, [isResetCarousels])

  useEffect(() => {
    if(applicationList.length && !isInitialSlideSet && initialSlide) {
      setIsInitialSlideSet(true)
      slider.current?.swiper.slideTo(initialSlide)
    }
  }, [applicationList, isInitialSlideSet])

  return (
    <div className={classNames.wrapper}>
      <h2 className={classNames.title} data-test={`${reducer}-section-header`}>
        <span className={classNames.content}>
          {title}
          {Boolean(applicationList.length) && (
            <CategoryLink reducer={reducer} tabIndex={tabIndexesByReducer[reducer]}/>
          )}
        </span>
        {Boolean(applicationList.length) && (
          <CarouselNavigation
            reducer={reducer}
            slider={slider}
            activeElem={activeElem}
            pagination={pagination}
            tabIndex={tabIndexesByReducer[reducer]}
          />
        )}
      </h2>
      <div className={classNames.swiperWrapper}>
        <EmptyAppList reducer={reducer} />
        <Swiper
          {...swiperConfig}
          ref={slider}
          onSwiper={(swiper) => {
            setSlidesPerGroup(swiper.params?.slidesPerGroup)
            setPagination(swiper.snapGrid)
          }}
          onResize={(swiper) => {
            setSlidesPerGroup(swiper.params?.slidesPerGroup)
            setPagination(swiper.snapGrid)
            setSnapIndex(swiper.isEnd ? (swiper.snapGrid.length ? swiper.snapGrid.length - 1 : swiper.snapIndex) : swiper.snapIndex)
          }}
          onSlidesGridLengthChange={(swiper) => {
            setSlidesPerGroup(swiper.params?.slidesPerGroup)
            setPagination(swiper.snapGrid)
          }}
          data-test={`carousel-${reducer}`}
          onSlideChange={onSlideChange}
        >
          {slides}
        </Swiper>
        {applicationInitialSaved && slidesPerGroup && (
          <div className={classNames.pagination}>
            {slidesPerGroup > 2 && (
              <Pagination
                paginationItems={pagination}
                handleMoveMainSlider={handleMoveMainSlider}
                snapIndex={snapIndex}
                tabIndex={tabIndexesByReducer[reducer] + 50}
                reducer={reducer}
              />
            )}
            {slidesPerGroup <= 2 && (
              <MobilePagination
                paginationItems={pagination}
                handleMoveMainSlider={handleMoveMainSlider}
                snapIndex={snapIndex}
                tabIndex={tabIndexesByReducer[reducer] + 50}
                reducer={reducer}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
