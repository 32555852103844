import cx from 'classnames'

import classNames from './styles.module.scss'


interface IProps {
  ind: number,
  activeElem: boolean,
  onClick: () => void,
  tabIndex?: number,
  dataTest?: string,
}

PaginationDot.defaultProps = {
  dataTest: undefined,
  tabIndex: undefined,
}

export default function PaginationDot({
  activeElem,
  onClick,
  tabIndex,
  dataTest,
}: IProps): JSX.Element {
  return (
    <div
      className={classNames.bullet}
      onClick={onClick}
      tabIndex={tabIndex}
      data-test={dataTest}
      onKeyDown={(ev) => {
        if(ev.key === 'Enter') {
          onClick()
        }
      }}
    >
      <span className={cx(classNames.dot, activeElem && classNames.active)}/>
    </div>
  )
}
