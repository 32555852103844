import { useAppDispatch, useAppSelector, useIframe, useSession, useTranslate } from '@common/hooks'
import getSortOrderTitle from '@common/utils/getSortOrderTitle'
import { unmountApplicationInfo } from '@redux/slices/application/application'
import { setPreviousListPage } from '@redux/slices/common'
import { fetchUserSlots } from '@redux/slices/userSlots/userSlots'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import AppCarousel from './widgets/AppCarousel'
import ServerRootPageGrid from './widgets/ServerRootPageGrid'


export interface IProps {
  showHackathonWinners: boolean
}

export default function AppList({
  showHackathonWinners,
}: IProps): JSX.Element {
  const { translate, interpolate } = useTranslate()
  const { isAuthorizedUser } = useSession()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const slotId = useAppSelector(store => store.common.data.slotId)
  const userSlots = useAppSelector(store => store.userSlots.data)
  const token = useAppSelector(store => store.common.data?.config?.token)
  const title = slotId
    ? interpolate(translate('App directory | Slot %s'), [slotId])
    : translate('Application Directory')

  const iframeParams = useMemo(() => ({
    title,
    url: '/apps',
    params: { slot: slotId ? String(slotId) : null, q: router.query.q || null },
    onDashboardHasUpdate: () => {
      void dispatch(fetchUserSlots())
    },
  }), [title, slotId, router.query.q])

  useIframe(iframeParams)

  useEffect(() => {
    void dispatch(setPreviousListPage(null))
    void dispatch(unmountApplicationInfo())
  }, [])

  useEffect(() => {
    if(isAuthorizedUser && userSlots) {
      void dispatch(fetchUserSlots())
    }
  }, [isAuthorizedUser])

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      <Head>
        <title>
          {title}
        </title>
        <meta
          property="og:title"
          content={title}
          key="title"
        />
      </Head>
      {!isClient && (
        <ServerRootPageGrid showHackathonWinners={showHackathonWinners}/>
      )}
      {isClient && (
        <main>
          {showHackathonWinners &&
            <AppCarousel
              title={translate(getSortOrderTitle('hackathonWinners'))}
              reducer="hackathonWinners"
              clientRequest
            />
          }
          <AppCarousel
            rowsPerSlide={3}
            title={translate(getSortOrderTitle('topEarning'))} reducer="topEarning"
          />
          <AppCarousel
            title={translate(getSortOrderTitle('trendingApps'))} reducer="trendingApps"
          />
          <AppCarousel
            title={translate(getSortOrderTitle('newAndPopular'))} reducer="newAndPopular"
          />
          <AppCarousel
            title={translate(getSortOrderTitle('recentlyUpdated'))}
            reducer="recentlyUpdated"
          />
          {token?.user_id && (
            <AppCarousel
              title={translate(getSortOrderTitle('recentlyUsed'))}
              reducer="recentlyUsed"
              clientRequest
            />
          )}
        </main>
      )}
    </>
  )
}
