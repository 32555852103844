import Dot from '@common/widgets/PaginationDot'
import cx from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from './styles.module.scss'


interface IProps {
  paginationItems: number[],
  handleMoveMainSlider: (ind: number) => void,
  snapIndex: number,
  tabIndex: number,
  reducer: AppListReducer,
}

export default function Pagination({
  paginationItems,
  handleMoveMainSlider,
  snapIndex,
  tabIndex,
  reducer,
}: IProps): JSX.Element | null {
  const [activeElem, setActive] = useState(0)
  const onDotClick = useCallback((ind: number) => {
    setActive(ind)
    handleMoveMainSlider(ind)
  }, [handleMoveMainSlider])

  const items = useMemo(() => {
    return paginationItems.map(
      (el, ind) => {
        return (
          <Dot key={ind} ind={ind} activeElem={activeElem === ind} onClick={() => onDotClick(ind)} tabIndex={tabIndex + ind} dataTest={`dot-${reducer}-${ind}`}/>
        )
      }
    )
  }, [paginationItems, activeElem, onDotClick])

  useEffect(() => {
    if(activeElem !== snapIndex) {
      setActive(snapIndex)
    }
  }, [snapIndex, activeElem])

  return (
    <div className={cx(classNames.wrapper)}>
      {items.length > 1 && items}
    </div>
  )
}
