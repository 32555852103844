import { emptyApplicationsArray, tabIndexesByReducer } from '@common/constants'
import { useAppSelector } from '@common/hooks'
import AppCard from '@common/widgets/AppCard'
import cx from 'classnames'

import CategoryLink from '../AppCarousel/CategoryLink'
import classNames from './styles.module.scss'

interface IProps {
  title: string,
  reducer: AppListReducer,
  rowsPerSlide?: number,
}

AppGrid.defaultProps = {
  rowsPerSlide: 1,
}

export default function AppGrid({
  title,
  reducer,
  rowsPerSlide,
}: IProps): JSX.Element {
  const applicationList = useAppSelector(store => store[reducer]?.data?.items || emptyApplicationsArray)
  return (
    <div className={classNames.wrapper}>
      <h2 className={classNames.title} data-test={`${reducer}-section-header`}>
        <span className={classNames.content}>
          {title}
          {Boolean(applicationList.length) && (
            <CategoryLink reducer={reducer} tabIndex={tabIndexesByReducer[reducer]} />
          )}
        </span>
      </h2>
      <div className={cx(classNames.grid, (rowsPerSlide || 1) > 1 && classNames.multipleRow)}>
        {applicationList.map((application) => (
          <div key={application.id} className={cx(classNames.gridItem, (rowsPerSlide || 1) > 1 && classNames.multipleCard)}>
            <AppCard
              {...application}
              application={application}
              dataTest={`app-${reducer}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
