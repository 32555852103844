import AppList from '@apps/AppList'
import type { IProps } from '@apps/AppList/AppList'
import { serverApi as api } from '@common/api'
import { fwdHeadersFromHttpReq } from '@common/api/fwdHeadersFromReq'
import { AppStoreUrls } from '@common/api/urls'
import { compositeQueryObjectValue } from '@common/constants'
import catchGetServerSide from '@common/utils/catchGetServerSide'
import {
  hackathonWinnersInitialize,
  newAndPopularInitialize,
  recentlyUpdatedInitialize,
  topEarningInitialize,
  trendingAppsInitialize,
} from '@redux/slices/applicationList'
import type { IData } from '@redux/slices/applicationList/applicationList.types'
import { wrapper } from '@redux/store'
import type { GetServerSideProps } from 'next'


export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(store => async ({ req }) => {
  const showHackathonWinners = process.env.ASP_HACKATHON_WINNERS === 'True'
  try {
    const headers = fwdHeadersFromHttpReq(req)
    const url = AppStoreUrls.app_list_composite()
    await api.get<{
      hackathonWinners: IData,
      newAndPopular: IData,
      topEarning: IData,
      trendingApps: IData,
      recentlyUpdated: IData,
    }>(url, {
      params: {
        'app-lists-params': JSON.stringify({
          paramsList: compositeQueryObjectValue.paramsList.filter(item => showHackathonWinners || item.key !== 'hackathonWinners'),
        }),
      },
      headers,
    })
      .then((data) => {
        const { hackathonWinners, newAndPopular, topEarning, trendingApps, recentlyUpdated } = data
        store.dispatch(topEarningInitialize(topEarning))
        store.dispatch(newAndPopularInitialize(newAndPopular))
        store.dispatch(trendingAppsInitialize(trendingApps))
        store.dispatch(recentlyUpdatedInitialize(recentlyUpdated))
        if(showHackathonWinners) {
          store.dispatch(hackathonWinnersInitialize(hackathonWinners))
        }
      })
  } catch (e) {
    return catchGetServerSide(e)
  }
  return {
    props: {
      showHackathonWinners,
    },
  }
})

// http://localhost:3000/apps
export default function ApplicationList({
  showHackathonWinners,
}: IProps): JSX.Element {
  return <AppList showHackathonWinners={showHackathonWinners}/>
}
