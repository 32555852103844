import type { Swiper } from 'swiper'

export default function handleGetSwiperIndex(swiper?: Swiper): number {
  if(!swiper) {
    return 0
  }
  const isEnd = swiper.activeIndex + (swiper.params.slidesPerGroup || 0) >= swiper.slides.length

  if(isEnd) {
    return swiper.activeIndex + (swiper.params.slidesPerGroup || 0)
  }
  return swiper.activeIndex
}
